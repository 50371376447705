import React, { useRef } from "react";
import { useAppContext } from "../../context/appContext";
import {
  Image,
  Badge,
  ListGroup,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Row,
  Col,
} from "react-bootstrap";
import Flex from "../../components/Flex/index";
import { Link, useNavigate } from "react-router-dom";
import ListBMWrapper from "../../assets/wrappers/ListBM";
import moment from "moment";

const ListBM = () => {
  const {
    businesses: { data },
  } = useAppContext();
  console.log("🚀 ~ ListBM ~ data:", data)
  
  const isFocusRef = useRef(false);
  const navigate = useNavigate();

  const setFocusRef = (value) => {
    isFocusRef.current = value;
  };

  if (!data?.length) return <p>No data</p>;
  return (
    <div>
      <Row>
        {data.map((bm, index) => {
          return (
            // <ListBMWrapper>
            //   <ListGroup variant="flush">
            //     <ListGroup.Item
            //       className="item rounded"
            //       onClick={() => !isFocusRef.current && navigate(`${bm.id}/ads`)}
            //     >
            //       <Flex
            //         key={index}
            //         align="center"
            //         justify="space-between"
            //         className="mb-2 w-100"
            //       >
            //         <Flex align="center">
            //           <Image
            //             src={bm.picture?.data?.url}
            //             className="rounded-circle"
            //           />
            //           <div className="ms-2">
            //             <p className="mb-0">{bm.name}</p>
            //             <Badge bg="secondary">{bm.id}</Badge>
            //           </div>
            //         </Flex>
            //         <Flex>
            //           <Link
            //             onMouseOver={() => setFocusRef(true)}
            //             onMouseLeave={() => setFocusRef(false)}
            //             to={`${bm.id}/users`}
            //             className="link"
            //           >
            //             {bm.business_users.data.length || 0} user(s)
            //           </Link>
            //         </Flex>
            //         <Flex>
            //           <Link
            //             to={`${bm.id}/pages`}
            //             onMouseOver={() => setFocusRef(true)}
            //             onMouseLeave={() => setFocusRef(false)}
            //           >
            //             {bm.owned_pages?.data.length || 0} page(s)
            //           </Link>
            //         </Flex>
            //         <Flex align="center">
            //           <Button size="sm" variant="secondary" className="ms-2">
            //             Ad Account
            //           </Button>
            //         </Flex>
            //       </Flex>
            //     </ListGroup.Item>
            //   </ListGroup>
            // </ListBMWrapper>
            <>
              <Col key={index} xl={3} lg={3} md={4} sm={6}>
                <Card>
                  <img
                    style={{ height: 120, objectFit: "cover" }}
                    src={bm.profile_picture_uri}
                  />
                  <CardBody>
                    <CardTitle tag="h3">{bm.name}</CardTitle>
                    <CardSubtitle className="mb-2 text-muted" tag="h6">
                      Verified:{" "}
                      <span
                        className={
                          bm.verification_status == "verified"
                            ? "text-success"
                            : ""
                        }
                      >
                        {bm.verification_status == "verified" ? "Yes" : "No"}
                      </span>
                    </CardSubtitle>
                    <CardText>
                      <p className="m-0">
                        <span> Created Time: </span>
                        <span>
                          {moment(bm?.created_time).format(
                            "MM/DD/YYYY - HH:mm"
                          )}
                        </span>
                      </p>
                      <p className="m-0">ID: {bm?.id}</p>
                      <p className="m-0">
                        Owned Ad Accounts:{" "}
                        {bm?.owned_ad_accounts?.data?.length || 0}
                      </p>
                      <p className="m-0">
                        Users: {bm?.business_users?.data?.length || 0}
                      </p>
                      <p className="m-0">
                        Pages: {bm?.owned_pages?.data?.length || 0}
                      </p>
                    </CardText>
                    <Flex column align="center">
                      <Button as={Link} to={`${bm.id}/ads`}>
                        Ad Accounts
                      </Button>
                      <Button
                        variant="info"
                        className="mt-2"
                        as={Link}
                        to={`${bm.id}/pages`}
                      >
                        Pages
                      </Button>
                      <Button
                        variant="secondary"
                        className="mt-2"
                        as={Link}
                        to={`${bm.id}/users`}
                      >
                        Users management
                      </Button>
                    </Flex>
                  </CardBody>
                </Card>
              </Col>
            </>
          );
        })}
      </Row>
    </div>
  );
};

export default ListBM;
