import React from "react";

const Footer = () => {
  return (
    <div className='footer'>
      <h3>AdCamp.Live</h3>
      <div className='footer-link'>
        <a href='/privacy-policy'>Privacy policy</a>
        <p className='divider'>|</p>
        <a href='/disclaimer'>Disclaimer</a>
      </div>

      <p style={{ color: "white", marginTop: 4 }}>
        Contact: 4 Rue de la Bergerie, Hourtin 33990, France
      </p>
    </div>
  );
};

export default Footer;
