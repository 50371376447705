import React, { useEffect, useState } from "react";
import Flex from "../../components/Flex";
import { Image, Badge } from "react-bootstrap";
import { useAppContext } from "../../context/appContext";
import { useParams } from "react-router-dom";
import ListPages from "../Fanpage/List";

const BmPage = () => {
  const {
    businesses: { bmSelected, loading },
    getBmInfo,
    user,
    token
  } = useAppContext();
  console.log(
    "🚀 ~ file: BmPage.js ~ line 14 ~ BmPage ~ bmSelected",
    bmSelected
  );
  const params = useParams();

  useEffect(() => {
    getBmInfo(params.id, token);
  }, [params.id, user]);

  return (
    <div>
      <Flex align="center" className="mb-4">
        <Image
          src={bmSelected?.picture?.data?.url}
          className="rounded-circle"
        />
        <div className="ms-2">
          <p className="mb-0">{bmSelected?.name}</p>
          ID: <Badge bg="secondary">{bmSelected?.id}</Badge>
        </div>
      </Flex>
      <ListPages data={bmSelected?.owned_pages?.data} loading={loading} />
    </div>
  );
};

export default BmPage;
