import axios from "../utils/axios";
import graphAxios from "../utils/graph-axios";

const get = ({ userId, link, fbAccessToken }) => {
  if (link) return graphAxios.get(link);
  return graphAxios.get(`/${userId}/accounts`, {
    params: {
      access_token: fbAccessToken,
      fields: ["link", "name", "fan_count", "category"].join(","),
    },
  });
};
const
 getById = ({ id, fbAccessToken }) => {
  return graphAxios.get(`/${id}`, {
    params: {
      access_token: fbAccessToken,
      fields: ["link", "name", "fan_count", "category", "access_token"].join(","),
    },
  });
};

const getPosts = ({ pageId, access_token, link }) => {
  if (link) return graphAxios.get(link);

  return graphAxios.get(`/${pageId}/feed`, {
    params: {
      access_token,
      limit: 10,
      fields:
        "story,message,full_picture,permalink_url,created_time,attachments",
    },
  });
};

const FanpageAPI = {
  get,
  getById,
  getPosts,
};
export default FanpageAPI;
