import React, { useEffect } from "react";
import { Loading } from "../../components";
import { useAppContext } from "../../context/appContext";
import ListBM from "./ListBM";

const Business = () => {
  const {
    getBusinesses,
    user,
    businesses: { loading },
  } = useAppContext();
    console.log("🚀 ~ Business ~ user:", user)

  useEffect(() => {
    if (user?.userID) {
      getBusinesses({
        userID: user.userID,
        accessToken: user.accessToken,
      });
    }
  }, [user]);

  if (loading) return <Loading />;

  return (
    <div>
      <h3>List Businesses</h3>
      <ListBM />
    </div>
  );
};

export default Business;
