import Axios from "axios";
import { notification } from "antd-notifications-messages";

// axios
const axios = Axios.create({
  // baseURL: "/api/v1",
  baseURL: "http://localhost:9000/api/v1",
});
// request

const showError = (message) => {
  // notification use
  notification({
    type: "error",
    title: "Maybe need re-login!",
    message,
    duration: 5000,
  });
};

axios.interceptors.request.use(
  (config) => {
    config.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
      "token"
    )}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// response

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    showError(error?.response?.data?.error?.message || error?.message);
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.reload();
    }
    return Promise.reject(error?.response?.data || error?.message);
  }
);

export default axios;
