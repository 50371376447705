import axios from "../utils/axios";
import graphAxios from "../utils/graph-axios";

const getAds = (bmID, fbAccessToken) => {
  return graphAxios.get(
    `/${bmID}/owned_ad_accounts?fields=account_id,account_status,age,attribution_spec,balance,amount_spent,timezone_id,timezone_name,timezone_offset_hours_utc,owner,currency,created_time&limit=200`,
    {
      params: {
        access_token: fbAccessToken,
      },
    }
  );
};

const getPersonalAds = (fbAccessToken) => {
  return graphAxios.get(
    `/me?fields=personal_ad_accounts.limit(200){account_id,account_status,age,attribution_spec,balance,amount_spent,timezone_id,timezone_name,timezone_offset_hours_utc,owner,currency,created_time}`,
    {
      params: {
        access_token: fbAccessToken,
      },
    }
  );
};
const getAdAccount = async (link, fbAccessToken) => {
  if (link) return graphAxios.get(link);
  return graphAxios.get(
    `/me?fields=adaccounts.limit(10){account_id,name,account_status,age,attribution_spec,balance,amount_spent,timezone_id,timezone_name,timezone_offset_hours_utc,owner,currency,created_time}`,
    {
      params: {
        access_token: fbAccessToken,
      },
    }
  );
};
const getById = (id, fbAccessToken) => {
  return graphAxios.get(`/${id}`, {
    params: {
      access_token: fbAccessToken,
      fields: ["name", "id"].join(","),
    },
  });
};

const AdsAPI = {
  getPersonalAds,
  getAdAccount,
  getAds,
  getById,
};
export default AdsAPI;
