import axios from "../utils/axios";
import graphAxios from "../utils/graph-axios";

const getCampaigns = (adAccount, fbAccessToken) => {
  return graphAxios.get(
    `/${adAccount}/campaigns?fields=name,status,start_time,stop_time,created_time,updated_time,account_id,special_ad_category,objective,daily_budget,lifetime_budget,budget_remaining,spend_cap,ad_strategy_group_id&limit=10`,
    {
      params: {
        access_token: fbAccessToken,
      },
    }
  );
};

const insights = ({ id, accessToken, time_range }) => {
  return graphAxios.get(`/${id}/insights`, {
    params: {
      access_token: accessToken,
      time_range,
    },
  });
};


const CampaignAPI = {
  getCampaigns,
  insights,
};
export default CampaignAPI;
